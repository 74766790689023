<!-- 抢修任务 -->
<template>
  <div id="test-task">
    <div class="main">
      <div class="title">
        <img src="../../assets/images/LTicon.png" alt="" />
        <span>抢修任务</span>
      </div>
      <div class="content">
        <div class="query-form">
          <el-form class="form" ref="form" :inline="true" :model="requestParam">
            <!-- <el-form-item label="变配电站">
              <el-select :popper-append-to-body="false" v-model="requestParam.stationId" placeholder="请选择" clearable>
                <el-option v-for="item in stationData" :key="item.id" :label="item.stationName"
                  :value="item.id"></el-option>
              </el-select>
            </el-form-item> -->
            <el-form-item label="变配电站">
              <el-input v-model.trim="requestParam.stationName" placeholder="请输入任务标题" clearable></el-input>
            </el-form-item>
            <el-form-item label="任务标题">
              <el-input v-model.trim="requestParam.taskName" placeholder="请输入任务标题" clearable></el-input>
            </el-form-item>
            <el-form-item label="任务编号">
              <el-input v-model.trim="requestParam.taskNum" placeholder="请输入任务编号" clearable></el-input>
            </el-form-item>
            <el-form-item label="任务状态">
              <el-select :popper-append-to-body="false" v-model="requestParam.state" placeholder="请选择" clearable>
                <el-option label="保存" value="1"></el-option>
                <el-option label="待执行" value="2"></el-option>
                <el-option label="执行中" value="3"></el-option>
                <el-option label="完成待审批" value="4"></el-option>
                <el-option label="终止" value="5"></el-option>
                <el-option label="审批通过" value="6"></el-option>
                <el-option label="审批驳回" value="7"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="运维审批人">
              <el-input v-model.trim="requestParam.approverUser" placeholder="请输入负责人名称" clearable></el-input>
            </el-form-item>
            <el-form-item label="运维执行人">
              <el-input v-model.trim="requestParam.operationUser" placeholder="请输入执行人名称" clearable></el-input>
            </el-form-item>

            <el-form-item class="query-date" label="计划开始时间">
              <el-date-picker v-model="requestParam.planStartTimeVo" :append-to-body="false" type="date"
                value-format="yyyy-MM-dd" placeholder="请选择计划开始时间"></el-date-picker>
              <!-- <span> ~ </span>
              <el-date-picker v-model="requestParam.planEndTimeVo" :append-to-body="false" type="date"
                value-format="yyyy-MM-dd" placeholder="请选择计划结束时间" :picker-options="pickerOptions"></el-date-picker> -->
            </el-form-item>
            <el-form-item class="query-date" label="计划开始时间">
              <el-date-picker v-model="requestParam.planEndTimeVo" :append-to-body="false" type="date"
                value-format="yyyy-MM-dd" placeholder="请选择计划结束时间" :picker-options="pickerOptions"></el-date-picker>
            </el-form-item>
            <el-form-item class="query-date" label="实际开始时间">
              <el-date-picker v-model="requestParam.startTimeVo" :append-to-body="false" type="date"
                value-format="yyyy-MM-dd" placeholder="请选择任务实际开始时间" @change="checkDate"></el-date-picker>
              <!-- <span> ~ </span>
              <el-date-picker v-model="requestParam.endTimeVo" :append-to-body="false" type="date"
                value-format="yyyy-MM-dd" placeholder="请选择任务实际结束时间" :picker-options="pickerOptions"
                @change="checkDate"></el-date-picker> -->
            </el-form-item>
            <el-form-item class="query-date" label="实际开始时间">
              <el-date-picker v-model="requestParam.endTimeVo" :append-to-body="false" type="date"
                value-format="yyyy-MM-dd" placeholder="请选择任务实际结束时间" :picker-options="pickerOptions"
                @change="checkDate"></el-date-picker>
            </el-form-item>
          </el-form>
          <div class="query-button">
            <el-button type="primary" icon="el-icon-search" @click="formQuery">查询</el-button>
            <el-button v-if="isButtonPower('createButtonc')" type="primary" icon="el-icon-s-order"
              @click="createDialog">任务发布</el-button>
          </div>
        </div>

        <el-table class="table" :data="tableData">
          <el-table-column align="center" type="index" label="序号" width="75">
            <template slot-scope="scope">
              <span>{{
            (requestParam.pageNum - 1) * requestParam.pageSize +
            scope.$index +
            1
          }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="stationName" label="变配电站" width="150">
          </el-table-column>
          <el-table-column align="center" prop="stationNumber" label="场站编码" width="150">
          </el-table-column>
          <el-table-column align="center" prop="companyName" label="运维单位">
          </el-table-column>
          <el-table-column align="center" prop="taskNum" label="抢修任务编号" width="150">
          </el-table-column>
          <el-table-column align="center" prop="taskName" label="任务标题" width="150">
          </el-table-column>
          <el-table-column align="center" prop="stateDict" label="任务状态" width="150">
            <template slot-scope="scope">
              <div v-show="scope.row.stateDict === '保存'" style="color: #aed6ff">
                {{ scope.row.stateDict }}
              </div>
              <div v-show="scope.row.stateDict === '待执行'" style="color: #66b1ff">
                {{ scope.row.stateDict }}
              </div>
              <div v-show="scope.row.stateDict === '执行中'" style="color: yellow">
                {{ scope.row.stateDict }}
              </div>
              <div v-show="scope.row.stateDict === '完成待审批'" style="color: #67c23a">
                {{ scope.row.stateDict }}
              </div>
              <div v-show="scope.row.stateDict === '待审批'" style="color: #67c23a">
                {{ scope.row.stateDict }}
              </div>
              <div v-show="scope.row.stateDict === '终止'" style="color: red">
                {{ scope.row.stateDict }}
              </div>
              <div v-show="scope.row.stateDict === '通过'" style="color: #0dbc79">
                {{ scope.row.stateDict }}
              </div>
              <div v-show="scope.row.stateDict === '驳回'" style="color: #d12018">
                {{ scope.row.stateDict }}
              </div>
            </template>
          </el-table-column>

          <el-table-column align="center" prop="approverUser" label="运维审批人" width="100">
          </el-table-column>
          <el-table-column align="center" prop="operationUser" label="运维执行人" width="100">
          </el-table-column>
          <el-table-column align="center" prop="createName" label="创建人" width="100">
          </el-table-column>
          <el-table-column align="center" prop="planStartTime" label="计划开始时间" width="150">
          </el-table-column>
          <el-table-column align="center" prop="planEndTime" label="计划结束时间" width="150">
          </el-table-column>
          <el-table-column align="center" prop="startTime" label="任务实际开始时间" width="150">
          </el-table-column>
          <el-table-column align="center" prop="endTime" label="任务实际结束时间" width="150">
          </el-table-column>
          <el-table-column align="center" prop="submitTime" label="提交时间" width="150">
          </el-table-column>
          <el-table-column align="center" prop="createTime" label="创建时间" width="175">
          </el-table-column>
          <el-table-column align="center" prop="remark" label="备注" width="150">
          </el-table-column>
          <el-table-column align="center" label="操作" prop="operate" width="250" fixed="right">
            <template slot-scope="scope">
              <div class="operate">
                <div class="flex-row" v-show="scope.row.state === 1 || scope.row.state === 7">
                  <div v-if="isButtonPower('submitButtonc')">
                    <el-button type="text" @click="statusDialog(scope.row, 2)">提交</el-button>
                    <span>|</span>
                  </div>
                  <div v-if="isButtonPower('editButtonc')">
                    <el-button type="text" @click="updateDialog(scope.row)">编辑</el-button>
                    <span>|</span>
                  </div>
                </div>
                <div v-show="scope.row.state === 4 && scope.row.approverId==userId">
                  <div v-if="isButtonPower('	approveButtonc')">
                    <el-button type="text" @click="approveDialog(scope.row)">审批</el-button>
                    <span>|</span>
                  </div>
                </div>
                <!-- <div >
                  <div >
                    <el-button type="text" @click="approveRejectDialog(scope.row)">编辑111</el-button>
                    <span>|</span>
                  </div>
                </div> -->
                <div v-show="scope.row.state === 2">
                  <div v-if="isButtonPower('executeButton')">
                    <el-button type="text" @click="executeDialog(scope.row)">执行</el-button>
                    <span>|</span>
                  </div>
                </div>
                <div v-show="scope.row.state === 3">
                  <div v-if="isButtonPower('completeButtonc')">
                    <el-button type="text" @click="completeDialog(scope.row, 4)">完成</el-button>
                    <span>|</span>
                  </div>
                </div>
                <!-- 测试要求执行/完成/待审核状态可以终止 -->
                <div v-show="scope.row.state === 2 || scope.row.state === 3 || scope.row.state === 4">
                  <div v-if="isButtonPower('stopButtonc')">
                    <el-button type="text" @click="statusDialog(scope.row, 5)">终止</el-button>
                    <span>|</span>
                  </div>
                </div>
                <div>
                  <el-button type="text" @click="viewDialog(scope.row)">详情</el-button>
                  <span>|</span>
                </div>
                <div v-if="isButtonPower('deleteButtonc')">
                  <el-button type="text" @click="deleteDialog(scope.row)">删除</el-button>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <button class="home-page" @click="homePage">首页</button>
          <el-pagination :current-page="requestParam.pageNum" :page-sizes="[10, 20, 30, 40]"
            layout="prev, pager, next, sizes,slot" prev-text="上一页" next-text="下一页" :total="total" background
            @size-change="changeSize" @current-change="changeCurrent">
          </el-pagination>
          <button class="tail-page" @click="tailPage">尾页</button>
          <div class="jump-pagination">
            <span class="text">跳至</span>
            <el-input v-model.number="inputValue" size="mini" class="pagination-input" @input="checkPageNum"
              @change="inputValueChange"></el-input>
            <span class="text">页</span>
            <button class="confirm" @click="confirm">确定</button>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="查看详情" :visible.sync="viewVisible" width="800px" @close="detailsClose">
      <div class="dialog-content">
        <el-form class="dialog-form" ref="taskForm" :inline="true" :model="detailsData">
          <div class="details-title">
            <div class="square"></div>
            <div>任务明细</div>
          </div>
          <el-form-item label="场站名称">
            <el-input v-model="detailsData.stationName" :readonly="true"></el-input>
          </el-form-item>
          <div class=""></div>
          <el-form-item label="任务名称">
            <el-input v-model="detailsData.taskName" :readonly="true">
            </el-input>
          </el-form-item>
          <el-form-item label="任务编号">
            <el-input v-model="detailsData.taskNum" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="运维单位">
            <el-input v-model="detailsData.companyName" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="创建人">
            <el-input v-model="detailsData.createName" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="单位负责人">
            <el-input v-model="detailsData.companyPrincipalName" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="运维执行人">
            <el-input v-model="detailsData.operationUser" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="运维审批人">
            <el-input v-model="detailsData.approverUser" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="到达时间">
            <el-input v-model="detailsData.arrivalTimeDict" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="任务状态">
            <el-input v-model="detailsData.stateDict" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="创建时间">
            <el-input v-model="detailsData.createTime" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="提交时间">
            <el-input v-model="detailsData.submitTime" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="计划开始时间">
            <el-input v-model="detailsData.planStartTime" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="计划结束时间" class="">
            <el-input v-model="detailsData.planEndTime" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="实际开始时间">
            <el-input v-model="detailsData.startTime" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="实际结束时间" class="">
            <el-input v-model="detailsData.endTime" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="审批时间" class="">
            <el-input v-model="detailsData.unknownTime" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="终止时间">
            <el-input v-model="detailsData.stopTime" :readonly="true"></el-input>
          </el-form-item>
          <div class=""></div>
          <el-form-item label="备注">
            <el-input type="textarea" v-model="detailsData.remark" :rows="3" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="抢修任务文件" class="special" v-if="pictureUrl">
            <el-image  style="width: 200px; height: 98px" :src="pictureUrl" fit="cover"
              :preview-src-list="previewList"></el-image>
              <!-- <div class="noImage" v-else >暂无</div> -->
          </el-form-item>
          <div class="details-title">
            <div class="square"></div>
            <div>抢修内容描述</div>
          </div>
          <el-form-item label="抢修说明">
            <el-input type="textarea" v-model="detailsData.grabMaintainTaskExplain" :rows="3"
              :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="备注">
            <el-input type="textarea" v-model="detailsData.itemRemark" :rows="3"
              :readonly="true"></el-input>
          </el-form-item>
          <el-form-item  class="long-label" label="抢修任务项文件">
            <el-image v-if="taskUrl" style="width: 200px; height: 98px" :src="taskUrl" fit="cover"
              :preview-src-list="previewList"></el-image>
              <div class="noImage" v-else >暂无</div>
          </el-form-item>
          <div class="" v-if="detailsData.state > 2">
            <div class="details-title">
              <div class="square"></div>
              <div>抢修执行结果</div>
            </div>
            <el-form-item label="抢修执行结果" class="special">
              <el-input v-model="detailsData.result" :readonly="true"></el-input>
            </el-form-item>
            <el-form-item label="抢修金额">
              <el-input v-model="detailsData.details.grabMaintainMoney" :readonly="true"></el-input>
            </el-form-item>
            <el-form-item label="工作明细">
              <el-input type="textarea" v-model="detailsData.details.grabMaintainTaskExplain" :rows="3"
                :readonly="true"></el-input>
            </el-form-item>
            <el-form-item  class="max-label" label="执行结果文件">
              <el-image v-if="detailsUrl" style="width: 200px; height: 98px" :src="detailsUrl" fit="cover"
                :preview-src-list="previewList"></el-image>
                 <div class="noImage" v-else >暂无</div>
            </el-form-item>
          </div>
          <div class="" v-if="detailsData.state == 6 || detailsData.state ==7">
            <div class="details-title" >
              <div class="square"></div>
              <div>审批结果</div>
            </div>
            <el-form-item label="审批结果" >
              <el-input class="block" v-model="detailsData.stateDict" :readonly="true"></el-input>
            </el-form-item>
  
            <el-form-item label="审批意见">
              <el-input type="textarea" v-model="detailsData.approverOpinion" :rows="3" :readonly="true"></el-input>
            </el-form-item>
            <el-form-item  label="评分" v-if="detailsData.userScore">
              <el-rate  v-model="detailsData.userScore.score" show-text allow-half text-color="#aed6ff" disabled>
              </el-rate>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <!-- <el-button @click="viewVisible = false">取 消</el-button>
        <el-button type="primary" @click="viewVisible = false">确 定</el-button> -->
      </div>
    </el-dialog>
    <el-dialog :title="requestType === 0 ? '新建抢修任务' : '修改抢修任务'" :visible.sync="dialogVisible" width="800px"
      @close="dialogClose('taskForm')">
      <div class="dialog-content">
        <el-form class="dialog-form" ref="taskForm" :inline="true" :model="taskForm" :rules="rules">
          <div class="details-title">
            <div class="square"></div>
            <div>任务明细</div>
          </div>
          <el-form-item label="变配电站" prop="stationId">
            <el-input style="width: 330px;" placeholder="请选择变配电站" v-model="stationName" :readonly="true">
              <template slot="append">
                <span @click="selectStation">选择配电站</span>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item label="任务标题" prop="taskName">
            <el-input class="" placeholder="请输入任务标题" v-model.trim="taskForm.taskName">
            </el-input>
          </el-form-item>
          <div class=""></div>
          <el-form-item label="运维单位" prop="companyId">
            <!-- <el-input class="block" placeholder="请输入任务标题" v-model.trim="taskForm.taskName">
            </el-input> -->
            <el-select :popper-append-to-body="false" clearable v-model="taskForm.companyId" placeholder="请选择运维单位"
              @change="changeObtainUser">
              <el-option v-for="item in ComList" :key="item.companyId" :label="item.companyName"
                :value="item.companyId"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="单位负责人" >
            <!-- <el-select :popper-append-to-body="false" v-model="taskForm.approverId" placeholder="请选择审批人">
              <el-option v-for="item in operationUser" :key="item.id" :label="item.userName"
                :value="item.id"></el-option>
            </el-select> -->
            <el-input placeholder="请输入单位负责人" :readonly="true" clearable v-model.trim="approverName"></el-input>
          </el-form-item>
          <el-form-item label="运维审批人" prop="approverId">
            <el-select :popper-append-to-body="false" v-model="taskForm.approverId" placeholder="请选择运维审批人">
              <el-option v-for="item in operationUserList" :key="item.userId" :label="item.userName"
                :value="item.userId"></el-option>
            </el-select>
            <!-- <el-input placeholder="请输入审批人" :readonly="true" clearable v-model.trim="approverName"></el-input> -->
          </el-form-item>
          <el-form-item label="运维执行人" prop="operationUserId">
            <el-select :popper-append-to-body="false" v-model="taskForm.operationUserId" placeholder="请选择运维执行人">
              <el-option v-for="item in manageUser" :key="item.userId" :label="item.userName"
                :value="item.userId"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="计划开始时间" prop="planStartTime">
            <el-date-picker v-model="taskForm.planStartTime" type="datetime" placeholder="请选择计划开始时间"
              :picker-options="pickerOptionsStart" value-format="yyyy-MM-dd HH:mm:ss" :append-to-body="false"
              @change="checkDateTime"></el-date-picker>
          </el-form-item>

          <el-form-item label="计划完成时间" prop="planEndTime">
            <el-date-picker v-model="taskForm.planEndTime" type="datetime" placeholder="请选择计划完成时间"
              value-format="yyyy-MM-dd HH:mm:ss" :append-to-body="false" @change="checkDateTime"
              :picker-options="pickerOptionsEnd"></el-date-picker>
          </el-form-item>
          <el-form-item label="到场时限" prop="arrivalTime">
            <el-radio-group v-model="taskForm.arrivalTime">
              <el-radio :label="1">30分钟</el-radio>
              <el-radio :label="2">60分钟</el-radio>
              <el-radio :label="3">90分钟</el-radio>
              <el-radio :label="4">120分钟</el-radio>
            </el-radio-group>
          </el-form-item>
          
          <el-form-item label="备注">
            <el-input placeholder="请输入备注信息" type="textarea" :rows="3" v-model.trim="taskForm.remark"></el-input>
          </el-form-item>
          <el-form-item label="图片">
            <el-upload action="" :auto-upload="false" :limit="1" accept="image/*" :file-list="fileList"
              :on-change="pictureChange" list-type="picture" :on-remove="handleRemove">
              <el-button size="small" type="primary">点击上传</el-button>
              <!-- <i class="el-icon-plus"></i> -->
              <span slot="tip" class="el-upload-tip">
                只能上传jpg/png文件,且不超过5M,建议分辨率900*400
              </span>
            </el-upload>
          </el-form-item>
          <div class="interval"></div>
          <div class="details-title">
            <div class="square"></div>
            <div>抢修内容描述</div>
          </div>

          <el-form-item label="抢修内容" prop="grabMaintainTaskExplain">
            <el-input placeholder="请输入抢修内容" type="textarea" :rows="3"
              v-model.trim="taskForm.grabMaintainTaskExplain"></el-input>
          </el-form-item>
          <!-- <el-form-item label="执行结果">
            <el-radio-group v-model="taskForm.resultStatus">
              <el-radio :label="1">完成</el-radio>
              <el-radio :label="2">未完成</el-radio>
            </el-radio-group>
          </el-form-item> -->
          <el-form-item label="备注">
            <el-input placeholder="请输入备注信息" type="textarea" :rows="3" v-model.trim="taskForm.itemRemark"></el-input>
          </el-form-item>
          <el-form-item label="图片">
            <el-upload action="" :auto-upload="false" :limit="1" accept="image/*" :file-list="taskFileList"
              :on-change="taskChange" list-type="picture" :on-remove="taskRemove">
              <el-button size="small" type="primary">点击上传</el-button>
              <!-- <i class="el-icon-plus"></i> -->
              <span slot="tip" class="el-upload-tip">
                只能上传jpg/png文件,且不超过5M,建议分辨率900*400
              </span>
            </el-upload>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="changeStatus(1, 'taskForm')">保存</el-button>
        <el-button v-if="requestType === 0" type="primary" @click="changeStatus(2, 'taskForm')">提交</el-button>
      </div>
    </el-dialog>
    <el-dialog title="删除" :visible.sync="deleteVisible" width="436px">
      <div class="dialog-text">确定要删除该数据吗？</div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="deleteVisible = false">取 消</el-button>
        <el-button type="primary" @click="deleteRushRepair">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog :title="statusType === 2 ? '提交' : '终止任务'" :visible.sync="statusVisible" width="436px">
      <div class="dialog-text">
        {{
            statusType === 2
              ? "任务一旦提交将无法修改,请确认"
              : "确定将状态改成终止吗？"
          }}
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="statusVisible = false">取 消</el-button>
        <el-button type="primary" @click="statusConfirm">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="执行结果" :visible.sync="completeVisible" width="800px" @close="statusClose('statusForm')">
      <div class="dialog-content">
        <el-form class="dialog-form" ref="statusForm" :model="completeResult" :rules="formRules" :inline="true">
          <el-form-item label="场站名称">
            <el-input v-model="detailsData.stationName" :readonly="true"></el-input>
          </el-form-item>
          <div class=""></div>
          <el-form-item label="任务名称">
            <el-input v-model="detailsData.taskName" :readonly="true">
            </el-input>
          </el-form-item>
          <el-form-item label="任务编号">
            <el-input v-model="detailsData.taskNum" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="运维单位">
            <el-input v-model="detailsData.companyName" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="创建人">
            <el-input v-model="detailsData.createName" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="单位负责人">
            <el-input v-model="detailsData.companyPrincipalName" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="运维执行人">
            <el-input v-model="detailsData.operationUser" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="运维审批人">
            <el-input v-model="detailsData.approverUser" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="创建时间">
            <el-input v-model="detailsData.createTime" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="任务状态">
            <el-input v-model="detailsData.stateDict" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="提交时间">
            <el-input v-model="detailsData.submitTime" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="到达时间">
            <el-input v-model="detailsData.arrivalTimeDict" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="计划开始时间">
            <el-input v-model="detailsData.planStartTime" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="计划结束时间" class="">
            <el-input v-model="detailsData.planEndTime" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="实际开始时间">
            <el-input v-model="detailsData.startTime" :readonly="true"></el-input>
          </el-form-item>
         
          <div class=""></div>
          <el-form-item label="备注">
            <el-input type="textarea" v-model="detailsData.remark" :rows="3" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="抢修任务文件" class="special" v-if="pictureUrl">
            <el-image style="width: 200px; height: 98px" :src="pictureUrl" fit="cover"
              :preview-src-list="previewList"></el-image>
          </el-form-item>


          <div class="details-title">
            <div class="square"></div>
            <div>抢修内容</div>
          </div>
          <el-form-item label="抢修说明">
            <el-input type="textarea" v-model="detailsData.grabMaintainTaskExplain" :rows="3"
              :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="备注">
            <el-input type="textarea" v-model="detailsData.itemRemark" :rows="3"
              :readonly="true"></el-input>
          </el-form-item>
          <el-form-item class="long-label" label="抢修任务项文件">
            <el-image v-if="taskUrl" style="width: 200px; height: 98px" :src="taskUrl" fit="cover"
              :preview-src-list="previewList"></el-image>
              <div class="noImage" v-else >暂无</div>
          </el-form-item>
          <div class="details-title">
            <div class="square"></div>
            <div>执行结果</div>
          </div>
          <el-form-item label="抢修金额" prop="grabMaintainMoney">
            <el-input class="" placeholder="请输入抢修金额" v-model="completeResult.grabMaintainMoney"></el-input>
          </el-form-item>
          <el-form-item label="抢修状态" prop="resultStatus">
            <el-select class="resultStatus" :popper-append-to-body="false" v-model="completeResult.resultStatus"
              placeholder="请选择" clearable>
              <el-option label="完成" :value="1"></el-option>
              <el-option label="未完成" :value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="结果说明" prop="grabMaintainTaskExplain">
            <el-input placeholder="请输入结果说明" type="textarea" :rows="3"
              v-model.trim="completeResult.grabMaintainTaskExplain"></el-input>
          </el-form-item>
          <el-form-item label="任务结果文件">
            <el-upload action="" :auto-upload="false" :limit="1" accept="image/*" :file-list="fileList"
              :on-change="pictureChange" list-type="picture" :on-remove="handleRemoveImg">
              <el-button size="small" type="primary">点击上传</el-button>
              <!-- <i class="el-icon-plus"></i> -->
              <span slot="tip" class="el-upload-tip">
                只能上传jpg/png文件,且不超过5M,建议分辨率900*400
              </span>
            </el-upload>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="completeVisible = false">取 消</el-button>
        <el-button type="primary" @click="completeConfirm('statusForm',3)">保 存</el-button>
        <el-button type="primary" @click="completeConfirm('statusForm',4)">完 成</el-button>
      </div>
    </el-dialog>
    <el-dialog title="审批结果" :visible.sync="approveVisible" width="800px" @close="statusClose('statusForm')">
      <div class="status-dialog">
        <el-form class="dialog-form" ref="taskForm" :inline="true" :model="detailsData">
          
          <div class="details-title">
            <div class="square"></div>
            <div>任务明细</div>
          </div>
          <el-form-item label="任务名称">
            <el-input v-model="detailsData.taskName" :readonly="true">
            </el-input>
          </el-form-item>
          <el-form-item label="任务编号">
            <el-input v-model="detailsData.taskNum" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="电站名称">
            <el-input v-model="detailsData.stationName" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="运维单位">
            <el-input v-model="detailsData.companyName" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="创建人">
            <el-input v-model="detailsData.createName" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="单位负责人">
            <el-input v-model="detailsData.companyPrincipalName" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="创建时间">
            <el-input v-model="detailsData.createTime" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="运维执行人">
            <el-input v-model="detailsData.operationUser" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="运维审批人">
            <el-input v-model="detailsData.approverUser" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="任务状态">
            <el-input v-model="detailsData.stateDict" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="提交时间">
            <el-input v-model="detailsData.submitTime" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="到达时间">
            <el-input v-model="detailsData.arrivalTimeDict" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="计划开始时间">
            <el-input v-model="detailsData.planStartTime" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="计划结束时间" class="">
            <el-input v-model="detailsData.planEndTime" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="实际开始时间">
            <el-input v-model="detailsData.startTime" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="实际结束时间" class="">
            <el-input v-model="detailsData.endTime" :readonly="true"></el-input>
          </el-form-item>
          <div class=""></div>
          <el-form-item label="备注">
            <el-input type="textarea" v-model="detailsData.remark" :rows="3" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="抢修任务文件" class="special" v-if="pictureUrl">
            <el-image style="width: 200px; height: 98px" :src="pictureUrl" fit="cover"
              :preview-src-list="previewList"></el-image>
          </el-form-item>
          <div class="details-title">
            <div class="square"></div>
            <div>抢修内容描述</div>
          </div>
          <el-form-item label="抢修说明">
            <el-input type="textarea" v-model="detailsData.grabMaintainTaskExplain" :rows="3"
              :readonly="true"></el-input>
          </el-form-item>
          <el-form-item v-if="taskUrl" class="long-label" label="抢修任务项文件">
            <el-image style="width: 200px; height: 98px" :src="taskUrl" fit="cover"
              :preview-src-list="previewList"></el-image>
          </el-form-item>
          <div class="details-title">
            <div class="square"></div>
            <div>执行结果</div>
          </div>
          <el-form-item label="抢修执行结果" class="special">
            <el-input v-model="detailsData.result" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="抢修金额">
            <el-input v-model="detailsData.details.grabMaintainMoney" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="工作明细">
            <el-input type="textarea" v-model="detailsData.details.grabMaintainTaskExplain" :rows="3"
              :readonly="true"></el-input>
          </el-form-item>
          <el-form-item v-if="detailsUrl" class="max-label" label="执行结果文件">
            <el-image style="width: 200px; height: 98px" :src="detailsUrl" fit="cover"
              :preview-src-list="previewList"></el-image>
          </el-form-item>
        </el-form>
        <div class="details-title">
            <div class="square"></div>
            <div>审批</div>
          </div>
        <el-form class="status-form" ref="statusForm" :model="approveResult" :rules="formRules" :inline="true">
          <!-- <el-form-item label="审批人">
            <el-input v-model="approveResult.approverUser" :readonly="true"></el-input>
          </el-form-item> -->

          <el-form-item label="审批结果" class="approve-result" prop="state">
            <el-radio-group v-model="approveResult.state">
              <el-radio :label="6">同意</el-radio>
              <el-radio :label="7">驳回</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="审批意见" prop="approverOpinion">
            <el-input placeholder="请输入审批意见" type="textarea" :rows="3"
              v-model.trim="approveResult.approverOpinion"></el-input>
          </el-form-item>
          <el-form-item label="评分">
            <el-rate v-model="approveResult.score" show-text allow-half text-color="#aed6ff">
            </el-rate>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="approveVisible = false">取 消</el-button>
        <el-button type="primary" @click="approveConfirm('statusForm')">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="执行" :visible.sync="executeVisible" width="431px" @close="statusClose('statusForm')">
      <div class="dialog-text">
        确定执行该任务。
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="executeVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateTaskStatus(id, 3, '', '')">确定</el-button>
      </div>
    </el-dialog>
    <!-- 变配电站选择 -->
    <stationDialog :status="dialogStatus" :stationId="stationId" @selectedStation="getStationName"
      @statusChange="changeDialog"></stationDialog>
  </div>
</template>
<script>
import { rushRepairApi } from "@/api/rushRepair.js";
import { getStaComList } from "@/api/testTask.js";
import { inspectionFormApi } from "@/api/inspectionForm.js";
import { userApi } from "@/api/user.js";
import stationDialog from "./commponents/stationDialog.vue";
export default {
  name: "",
  components: { stationDialog },
  data() {
    return {
      tableData: [],
      requestParam: {
        pageNum: 1,
        pageSize: 10,
        approverUser: "",
        stationId: "",
        operationUser: "",
        state: "",
        startTimeVo: null,
        endTimeVo: null,
        planEndTimeVo: null,
        planStartTimeVo: null,
        taskName: '',
        taskNum: '',
        stationName: '',
      },
      total: 0,
      inputValue: 1,
      viewVisible: false,
      deleteVisible: false,
      dialogVisible: false,
      dialogStatus: false,
      completeVisible: false,
      statusVisible: false,
      approveVisible: false,
      executeVisible: false,
      requestType: -1,
      statusType: 2,
      detailsData: {
        details: {
          grabMaintainMoney: "",
        },
        userScore: {},
      },
      taskForm: {
        stationId: "",
        taskName: "",
        planStartTime: "",
        planEndTime: "",
        arrivalTime: "",
        operationUserId: "",
        remark: "",
        approverId: "",
        grabMaintainTaskExplain: "",
        itemRemark: "",
        companyId: '',
      },
      defaultData: {
        stationId: "",
        taskName: "",
        planStartTime: "",
        planEndTime: "",
        arrivalTime: "",
        operationUserId: "",
        remark: "",
        approverId: "",
        grabMaintainTaskExplain: "",
        itemRemark: "",
      },
      stationName: "",
      id: "",
      rules: {
        stationId: [
          { required: true, message: "请选择变配电站", trigger: "change" },
        ],
        taskName: [
          { required: true, message: "请输入任务标题", trigger: "blur" },
        ],
        companyId: [
          { required: true, message: "请选择运维单位", trigger: "change" },
        ],
        planStartTime: [
          { required: true, message: "请选择计划开始时间", trigger: "change" },
        ],
        arrivalTime: [
          { required: true, message: "请选择到场时限", trigger: "change" },
        ],
        planEndTime: [
          { required: true, message: "请选择计划完成时间", trigger: "change" },
        ],
        operationUserId: [
          { required: true, message: "请选择执行人", trigger: "change" },
        ],
        approverId: [
          { required: true, message: "请选择运维审批人", trigger: "change" },
        ],
        grabMaintainTaskExplain: [
          { required: true, message: "请输入抢修内容", trigger: "blur" },
        ],
      },
      formRules: {
        grabMaintainTaskExplain: [
          {
            required: true,
            message: "请输入抢修工作明细说明",
            trigger: "blur",
          },
        ],
        grabMaintainMoney: [
          { required: true, message: "请输入抢修金额", trigger: "blur" },
          {
            pattern: /^\d+(\.\d+)?$/,
            message: "请输入正数",
            trigger: "blur",
          },
        ],
        approverId: [
          { required: true, message: "请选择审批人", trigger: "change" },
        ],
        resultStatus: [
          { required: true, message: "请选择抢修状态", trigger: "change" },
        ],
        approverOpinion: [
          { required: true, message: "请输入审批意见", trigger: "blur" },
        ],
        state: [
          { required: true, message: "请选择审批结果", trigger: "change" },
        ],
      },
      fileList: [],
      file: [],
      taskFileList: [],
      taskFile: [],
      previewList: [],
      completeResult: {
        grabMaintainTaskExplain: "",
        grabMaintainMoney: "",
        resultStatus: '',
      },
      approveResult: {
        state: "",
        approverOpinion: "",
        approverId: "",
        approverUser: "",
        score: null,
      },
      stationId: 0,
      pictureUrl: "",
      taskUrl: "",
      detailsUrl: "",
      operationUserList: [],
      manageUser: [],
      testItem: [],
      stationData: [],
      selectTestTask: [],
      taskItem: {},
      allButtonPower: [],
      pickerOptionsStart: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 86400000;
        },
      },
      approverName: '',
      ComList: [],
      operationUserId: '',
      typeItem:'',
      detailsItem:{},
      modifyDetails:'',
      userId:'',
    };
  },

  created() {
    this.$store.commit("increment", "运维管理");
    this.$store.commit("selectChild", "抢修任务");
    this.$store.commit("selectChildren", "");
  },
  mounted() {
    this.queryRushRepair();
    this.queryStationName();
    // this.queryUser(1);
    // this.queryUser(2);
    this.allButtonPower = sessionStorage.getItem('roleInfo');
    this.userId=JSON.parse(sessionStorage.getItem("user_info")).id
  },

  methods: {
    //获取运维单位列表
    gatComList() {
      getStaComList(this.taskForm.stationId).then(res => {
        this.ComList = res.data
        if (this.taskForm.companyId) {
          this.obtainUser(this.taskForm.companyId)
        }
      })
    },

    //选择运维单位
    changeObtainUser(value) {
      console.log(value);
      let obj = this.ComList.filter(item => item.companyId === value)[0]
      console.log(obj);
      //contactNameId
      // this.taskForm.approverId = obj.contactNameId
      this.approverName = obj.contactName
      this.manageUser = obj.comUserList
      this.operationUserList = obj.fuzerenUserList
      if (this.taskForm.operationUserId) {
        this.taskForm.operationUserId = null
      }
      if (this.taskForm.approverId) {
        this.taskForm.approverId = null
      }
    },
    //选择运维单位
    obtainUser(value) {
      console.log('1111', value);
      let obj = this.ComList.filter(item => item.companyId === value)[0]
      console.log(obj);
      //contactNameId
      // this.taskForm.approverId = obj.contactNameId
      this.approverName = obj.contactName
      this.manageUser = obj.comUserList
      this.operationUserList = obj.fuzerenUserList
    },
    //按钮权限
    isButtonPower(code) {
      if (this.allButtonPower) {
        let list = this.allButtonPower.includes(code);
        return list;
      } else {
        return false;
      }
    },
    getStationName(value) {
      if (value.id) {
        this.taskForm.stationId = value.id;
        this.stationName = value.stationName;
        this.dialogStatus = false;
        this.taskForm.companyId = ''
        this.taskForm.operationUserId = '',
          this.approverName = ''
        this.approverId = ''
        this.gatComList()
      }
    },
    //按钮权限
    isButtonPower(code) {
      if (this.allButtonPower) {
        let list = this.allButtonPower.includes(code);
        return list;
      } else {
        return false;
      }
    },
    changeDialog(value) {
      this.dialogStatus = value;
    },
    selectStation() {
      this.dialogStatus = true;
    },
    submitConfirm() {
      if (this.requestType === 0) {
        this.dialogConfirm("taskForm");
      } else {
        this.updateTaskStatus(this.id, 2, "", "");
      }
    },
    statusDialog(item, status) {
      this.id = item.id;
      this.statusType = status;
      this.statusVisible = true;
    },
    statusConfirm() {
      if (this.requestType === 0) {
        this.createRushRepair();
      } else {
        this.updateTaskStatus(this.id, this.statusType, "", "");
      }
    },
    statusClose(formName) {
      this.completeResult.grabMaintainTaskExplain = "";
      this.completeResult.grabMaintainMoney = "";
      this.approveResult = {
        state: "",
        approverOpinion: "",
        approverId: "",
        approverUser: "",
        score: null,
      };
      this.fileList = [];
      this.file = [];
      this.$refs[formName].resetFields();
    },
    dialogClose(formName) {
      this.stationName = "";
      this.stationId = 0;
      this.fileList = [];
      this.taskFileList = [];
      this.taskFile = [];
      this.file = [];
      this.ComList = [];
      this.approverName = ''
      this.taskForm = {
        stationId: "",
        taskName: "",
        planStartTime: "",
        planEndTime: "",
        arrivalTime: "",
        operationUserId: "",
        remark: "",
        approverId: "",
        grabMaintainTaskExplain: "",
        itemRemark: "",
        companyId: '',
      };
      this.requestType=''
      // Object.assign(this.taskForm, this.defaultData);
      this.$refs[formName].resetFields();
    },
    detailsClose() {
      this.previewList = [];
      this.pictureUrl = "";
      this.taskUrl = "";
      this.detailsUrl='';
    },
    taskRemove(file, fileList) {
      console.log("删除第2个图片");
      this.deletePicture(this.detailsData.operationGrabMaintainTaskItemId);
      this.taskFileList = [];
      this.taskFile = [];
    },
    handleRemove(file, fileList) {
      console.log("删除第1个图片");
      this.deletePicture(this.detailsData.id);
      this.fileList = [];
      this.file = [];
    },
    handleRemoveImg(file, fileList) {
      console.log("删除第1个图片");
      this.deletePicture(this.detailsItem.id);
      this.fileList = [];
      this.file = [];
    },
    taskChange(file, fileList) {
      this.taskFile = fileList.map((item) => item.raw);
      if (this.taskFile.length !== 0) {
        this.$message({
          message: "图片只能上传一张",
          duration: 3000,
          customClass: "messageText",
        });
      }
    },
    pictureChange(file, fileList) {
      this.file = fileList.map((item) => item.raw);
    },
    createDialog() {
      this.requestType = 0;
      Object.assign(this.taskForm, this.defaultData);
      this.dialogVisible = true;
    },
    updateDialog(data) {
      this.requestType = 1;
      this.id = data.id;
      this.queryTaskDetails();
      this.stationName = data.stationName;
      this.taskForm.stationId = data.stationId;
      this.taskForm.companyId = data.companyId;
      this.taskForm.taskName = data.taskName;
      this.taskForm.arrivalTime = data.arrivalTime;
      this.taskForm.approverId = data.approverId;
      this.taskForm.operationUserId = data.operationUserId;
      this.taskForm.planStartTime = data.planStartTime;
      this.taskForm.planEndTime = data.planEndTime;
      this.taskForm.remark = data.remark;
      this.modifyDetails=data.state
      if (data.operationGrabMaintainTaskItem) {
        if (data.operationGrabMaintainTaskItem.grabMaintainTaskExplain) {
          this.taskForm.grabMaintainTaskExplain =
            data.operationGrabMaintainTaskItem.grabMaintainTaskExplain;
        }
        if (data.operationGrabMaintainTaskItem.resultStatus) {
          this.taskForm.resultStatus =
            data.operationGrabMaintainTaskItem.resultStatus;
        }
        if (data.operationGrabMaintainTaskItem.remark) {
          this.taskForm.itemRemark = data.operationGrabMaintainTaskItem.remark;
        }
      }
      this.gatComList()
      this.dialogVisible = true;
    },
    viewDialog(data) {
      this.id = data.id;
      this.requestType = -1;
      this.detailsUrl=''
      this.queryTaskDetails();
      this.viewVisible = true;
    },
    deleteDialog(item) {
      this.id = item.id;
      this.deleteVisible = true;
    },
    executeDialog(item) {
      this.id = item.id;
      this.queryTaskItem();
      this.executeVisible = true;
    },
    formQuery() {
      this.requestParam.pageNum = 1;
      this.queryRushRepair();
    },
    selectChange(item) {
      item.showStatus = !item.showStatus;
    },
    completeDialog(item, status) {
      this.statusType = status;
      this.id = item.id;
      if (item.operationGrabMaintainTaskItem) {
        this.completeResult.operationGrabMaintainTaskItemId =
          item.operationGrabMaintainTaskItem.id;
      }
      this.queryTaskDetails();
      this.completeVisible = true;
    },
    approveDialog(item) {
      console.log(item);
      this.operationUserId = item.operationUserId
      this.id = item.id;
      this.approveResult.approverUser = item.approverUser;
      this.approveResult.approverId = item.approverId;
      this.queryTaskDetails();
      this.approveVisible = true;
    },
    //重新提交-
    approveRejectDialog(data){
      this.requestType = 1;
      this.id = data.id;
      this.queryTaskDetails();
      this.stationName = data.stationName;
      this.taskForm.stationId = data.stationId;
      this.taskForm.companyId = data.companyId;
      this.taskForm.taskName = data.taskName;
      this.taskForm.arrivalTime = data.arrivalTime;
      this.taskForm.approverId = data.approverId;
      this.taskForm.operationUserId = data.operationUserId;
      this.taskForm.planStartTime = data.planStartTime;
      this.taskForm.planEndTime = data.planEndTime;
      this.taskForm.remark = data.remark;
      if (data.operationGrabMaintainTaskItem) {
        if (data.operationGrabMaintainTaskItem.grabMaintainTaskExplain) {
          this.taskForm.grabMaintainTaskExplain =
            data.operationGrabMaintainTaskItem.grabMaintainTaskExplain;
        }
        if (data.operationGrabMaintainTaskItem.resultStatus) {
          this.taskForm.resultStatus =
            data.operationGrabMaintainTaskItem.resultStatus;
        }
        if (data.operationGrabMaintainTaskItem.remark) {
          this.taskForm.itemRemark = data.operationGrabMaintainTaskItem.remark;
        }
      }
      this.gatComList()
      this.dialogVisible = true;

    },
    checkDate() {
      if (this.requestParam.startTimeVo && this.requestParam.endTimeVo) {
        const startTime = new Date(this.requestParam.startTimeVo).getTime();
        const endTime = new Date(this.requestParam.endTimeVo).getTime();
        if (startTime > endTime) {
          this.requestParam.endTimeVo = null;
        }
      }
    },
    checkDateTime() {
      if (this.taskForm.planStartTime && this.taskForm.planEndTime) {
        const startTime = new Date(this.taskForm.planStartTime).getTime();
        const endTime = new Date(this.taskForm.planEndTime).getTime();
        if (startTime == endTime) {
          this.$message({
            message: "计划开始时间与计划结束时间不能相同",
            type: "warning",
            duration: 3000,
            customClass: "messageText",
          });
          this.taskForm.planEndTime = null;
        } else if (startTime > endTime) {
          this.$message({
            message: "计划开始时间不能大于计划结束时间",
            type: "warning",
            duration: 3800,
            customClass: "messageText",
          });
          this.taskForm.planEndTime = null;
        }
      }
    },
    approveConfirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let obj ={

          }
          rushRepairApi
        .taskScoring({ taskId: this.id, score: this.approveResult.score, operationUserId: this.operationUserId, type: 2 })
        .then((res) => {
            this.updateTaskStatus(
              this.id,
              this.approveResult.state,
              this.approveResult.approverId,
              this.approveResult.approverOpinion
            );
        });
        } else {
          return false;
        }
      });
    },
    // taskScoring() {
      
    // },
    completeConfirm(formName,type) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.typeItem= type==3 ? 1 : type==4 ? 2 :''
          this.updateTaskStatus(this.id, 4, "", "", this.completeResult);
        } else {
          return false;
        }
      });
    },
    changeStatus(state, formName) {

      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.statusType=state
          if (state === 2) {
            // 提交
            this.taskForm.state = state;
            this.statusVisible = true;
          } else {
            // 保存
            this.taskForm.state = state;
          //  modifyState=7
            this.dialogConfirm("taskForm");
          }
        } else {
          return false;
        }
      });
    },
    dialogConfirm(formName) {
      // this.$refs[formName].validate((valid) => {
      //   if (valid) {
      //     if (this.requestType === 0) {
      //       this.createRushRepair();
      //     } else {
      //       this.updateRushRepair();
      //     }
      //   } else {
      //     return false;
      //   }
      // });
      if (this.requestType === 0) {
        this.createRushRepair();
      } else {
        this.updateRushRepair();
      }
    },
    checkPageNum(e) {
      let value = e.replace(/[^\d]/g, ""); // 只能输入数字
      value = value.replace(/^0+(\d)/, "$1"); // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
      this.inputValue = value;
    },
    //页数输入
    inputValueChange() {
      const lastPage = Math.ceil(this.total / this.requestParam.pageSize);
      if (this.inputValue < 1) {
        this.inputValue = 1;
      } else {
        this.inputValue =
          this.inputValue < lastPage ? this.inputValue : lastPage;
      }
    },
    //首页
    homePage() {
      this.requestParam.pageNum = 1;
      this.queryRushRepair();
    },
    //尾页
    tailPage() {
      const lastPage = Math.ceil(this.total / this.requestParam.pageSize);
      this.requestParam.pageNum = lastPage;
      this.queryRushRepair();
    },
    confirm() {
      this.requestParam.pageNum = this.inputValue;
      this.queryRushRepair();
      this.inputValue = "";
    },
    changeSize(pageSize) {
      this.requestParam.pageSize = pageSize;
      this.queryRushRepair();
    },
    changeCurrent(pageNum) {
      this.requestParam.pageNum = pageNum;
      this.queryRushRepair();
    },
    queryRushRepair() {
      rushRepairApi.queryRushRepair(this.requestParam).then((res) => {
        if (res.code === 200) {
          this.tableData = res.data;
          this.total = res.total;
        }
      });
    },
    queryUser(type) {
      userApi.queryUser({ type }).then((res) => {
        if (res.code === 200) {
          if (type === 1) {
            this.operationUser = res.data;
          }
          if (type === 2) {
            this.operationUser = res.data;
          }
        }
      });
    },
    queryStationName() {
      inspectionFormApi.queryStationName().then((res) => {
        if (res.code === 200) {
          this.stationData = res.data;
        }
      });
    },
    queryTaskItem() {
      rushRepairApi.queryTaskItem({ id: this.id }).then((res) => {
        if (res.code === 200) {
          this.taskItem = res.data;
        }
      });
    },
    queryTaskDetails() {
      rushRepairApi.queryTaskDetails(this.id).then((res) => {
        if (res.code === 200) {
          this.detailsData = res.data;
          if (!res.data.details) {
            this.detailsData.details = {};
          }else{
            this.detailsItem=res.data.details
           let detailsDataUrl=res.data.details
            // detailsData
            this.completeResult.grabMaintainMoney=detailsDataUrl.grabMaintainMoney
            this.completeResult.resultStatus=res.data.resultStatus
            this.completeResult.grabMaintainTaskExplain=detailsDataUrl.grabMaintainTaskExplain
            if(res.data.itemDetailsFile){
              this.fileList=[{url:res.data.itemDetailsFile}]
            }
          }
          if (!res.data.userScore) {
            this.detailsData.userScore = {};
          }

          if (this.detailsData.taskFile) {
            if (this.requestType === -1) {
              this.pictureUrl = this.detailsData.taskFile.url;
              this.previewList.push(this.pictureUrl);
            }
            if (this.requestType === 1) {
              this.fileList = [{ url: this.detailsData.taskFile.url }];
            }
          }
          if (this.detailsData.taskItemFile) {
            if (this.requestType === -1) {
              this.taskUrl = this.detailsData.taskItemFile.url;
              this.previewList.push(this.taskUrl);
            }
            if (this.requestType === 1) {
              this.taskFileList = [{ url: this.detailsData.taskItemFile.url }];
            }
          }
          if (this.detailsData.itemDetailsFile) {
            if (this.requestType === -1) {
              this.detailsUrl = this.detailsData.itemDetailsFile.url;
              this.previewList.push(this.detailsUrl);
            }
          }else{
            this.detailsUrl=''
          }
        }
      });
    },
    unique(array) {
      return array.reduce((prev, next) => {
        return prev.includes(next) ? prev : [...prev, next];
      }, []);
    },
    createRushRepair() {
      let formData = new FormData();
      if (this.file.length !== 0) {
        this.taskForm.file = this.file[0];
      }
      if (this.taskFile.length !== 0) {
        this.taskForm.itemFile = this.taskFile[0];
      }
      for (let key in this.taskForm) {
        formData.append(key, this.taskForm[key]);
      }
      rushRepairApi.createRushRepair(formData).then((res) => {
        if (res.code === 200) {
          this.$message({
            message: res.message,
            type: "success",
            duration: 3000,
            customClass: "messageText",
          });
          this.$store.dispatch("queryMessage");
          this.dialogVisible = false;
          this.statusVisible = false;
          this.queryRushRepair();
        } else {
          this.$message({
            message: res.message,
            type: "error",
            duration: 3000,
            customClass: "messageText",
          });
        }
      });
    },
    updateRushRepair() {
      let formData = new FormData();
      this.taskForm.id = this.id;
      this.taskForm.state = this.modifyDetails ? this.modifyDetails : 1;
      if (this.detailsData.operationGrabMaintainTaskItemId) {
        this.taskForm.operationGrabMaintainTaskItemId =
          this.detailsData.operationGrabMaintainTaskItemId;
      }

      for (let key in this.taskForm) {
        formData.append(key, this.taskForm[key]);
      }
      // if(){
      //   this.taskForm.state
      // }
      rushRepairApi.updateRushRepair(formData).then((res) => {
        if (res.code === 200) {
          this.$message({
            message: res.message,
            type: "success",
            duration: 3000,
            customClass: "messageText",
          });
          this.$store.dispatch("queryMessage");
          if (this.file.length !== 0 || this.taskFile.length !== 0) {
            this.updatePicture();
          }
          this.dialogVisible = false;
          this.queryRushRepair();
          this.modifyDetails=''
        } else {
          this.$message({
            message: res.message,
            type: "error",
            duration: 3000,
            customClass: "messageText",
          });
        }
      });
    },
  
    updateTaskStatus(id, status, approverId, approverOpinion, object = {}) {
      let formData = new FormData();
      if (Object.keys(object).length !== 0) {
        let params = {
          ...object,
          type: this.typeItem,
          id:this.detailsItem.id ?  this.detailsItem.id :''
        };

        if (this.file.length !== 0) {
          params.taskId = this.id;
          params.itemDetailsFile = this.file[0];
        }
        for (let key in params) {
          formData.append(key, params[key]);
        }
      }

      rushRepairApi
        .updateTaskStatus(id, status, approverId, approverOpinion, formData)
        .then((res) => {
          if (res.code === 200) {
            this.$message({
              message: res.message,
              type: "success",
              duration: 3000,
              customClass: "messageText",
            });
            // if (status === 6 || status === 7) {
            //   this.taskScoring();
            // }
            if (status === 4) {
              if (this.file.length !== 0) {
                // this.updatePicture();
              }
            }
            this.$store.dispatch("queryMessage");
            this.approveVisible = false;
            this.completeVisible = false;
            this.statusVisible = false;
            this.executeVisible = false;
            this.queryRushRepair();
          } else {
            this.$message({
              message: res.message,
              type: "error",
              duration: 3000,
              customClass: "messageText",
            });
          }
        });
    },
    deleteRushRepair() {
      rushRepairApi.deleteRushRepair(this.id).then((res) => {
        if (res.code === 200) {
          this.$message({
            message: res.message,
            type: "success",
            duration: 3000,
            customClass: "messageText",
          });
          this.deleteVisible = false;
          this.queryRushRepair();
        } else {
          this.$message({
            message: res.message,
            type: "error",
            duration: 3000,
            customClass: "messageText",
          });
        }
      });
    },
    updatePicture() {
      let formData = new FormData();
      if (this.file.length !== 0) {
        formData.append("taskId", this.detailsData.id);
        formData.append("taskFile", this.file[0]);
      }
      if (this.taskFile.length !== 0) {
        formData.append(
          "itemTaskId",
          this.detailsData.operationGrabMaintainTaskItemId
        );
        formData.append("itemTaskFile", this.taskFile[0]);
      }
      rushRepairApi.updatePicture(formData).then((res) => { });
    },
    deletePicture(id) {
      rushRepairApi.deletePicture(id).then((res) => { });
    },
  },
  watch: {
    "taskForm.stationId": {
      // 执行方法
      handler(newValue, oldValue) {
        if (newValue) {
          this.stationId = newValue;
        }
      },
      deep: true, // 深度监听
      immediate: true, // 第一次改变就执行
    },
  },
  computed: {
    pickerOptionsEnd() {
      const _this = this;
      return {
        disabledDate(time) {
          const limitDate = new Date(_this.taskForm.planStartTime);
          limitDate.setHours(0, 0, 0, 0); // 重置时间为当天的开始时间

          return time.getTime() < limitDate.getTime();
        },
      };
    },
    pickerOptions() {
      const _this = this;
      return {
        disabledDate(time) {
          const limitDate = new Date(_this.requestParam.startTimeVo);
          limitDate.setHours(0, 0, 0, 0); // 重置时间为当天的开始时间

          return time.getTime() < limitDate.getTime();
        },
      };
    },
  },
};
</script>
<style src="@/utils/style/public-style.css" scoped></style>
<style scoped>
#test-task {
  width: 100%;
  min-height: 781px;
  background: url("../../assets/images/rightBG.png") no-repeat;
  background-position: left left;
  background-size: cover;
  font-size: 16px;
}

.main {
  width: 100%;
  padding: 20px 26px 28px 25px;
  box-sizing: border-box;
}

.form .query-date :deep() .el-form-item__label {
  width: 93px;
}

/* 弹窗 */
.dialog-content {
  margin: 20px 41px 0 41px;
}

.form-title {
  font-family: "Arial Bold", "Arial Normal", "Arial", sans-serif;
  font-weight: 700;
  color: #c9d4f1;
  font-size: 16px;
  margin-bottom: 16px;
}

.dialog-form :deep() .el-input-group__append {
  background: #143168;
  color: #fff;
  border: 0;
  cursor: pointer;
}

/* input */
.dialog-form :deep() .el-input {
  width: 231px;
}

.dialog-form :deep() .el-input .el-input__inner {
  height: 35px;
  width: 100%;
  background-color: #061b45;
  border: 1px solid #09295b;
  font-size: 12px;
  color: #aed6ff;
}

.dialog-form :deep() .block {
  width: 566px;
}

.dialog-form :deep() .el-textarea textarea {
  width: 566px;
  background-color: #061b45;
  border: 1px solid #09295b;
  /* border: 0; */
  font-size: 12px;
  color: #aed6ff;
}

.dialog-form :deep() .special .el-form-item__label {
  width: 93px;
  /* margin-right: 6px; */
}

.dialog-form :deep() .long-label .el-form-item__label {
  width: 93px;
}

.dialog-form :deep() .max-label .el-form-item__label {
  width: 117px;
  margin-left: -28px;
}

.dialog-form :deep() .el-radio {
  margin-right: 16px;
  color: #aed6ff;
}

.el-upload-tip {
  color: #e8f4ff;
  font-size: 12px;
  margin-left: 8px;
}

.dialog-form :deep() .el-form-item__label {
  width: 93px;
  padding-right: 0;
  color: #e8f4ff;
  font-size: 12px;
  text-align: right;
  margin-right: 10px;
  box-sizing: border-box;
}

/*时间日期选择器默认样式修改 */
.dialog-form :deep() .el-picker-panel .el-input {
  width: 100%;
}

::v-deep .el-time-panel {
  background: #041c4c;
  border: 0;
  color: #aed6ff;
}

::v-deep .el-time-panel__footer {
  border-color: #aed6ff;
}

::v-deep .el-time-spinner__item {
  color: #aed6ff;
}

::v-deep .el-time-spinner__item.active:not(.disabled) {
  color: #aed6ff;
}

::v-deep .el-time-panel__btn {
  color: #aed6ff;
}

::v-deep .el-time-panel__btn.confirm {
  color: #409eff;
}

::v-deep .el-picker-panel__footer {
  color: #aed6ff;
  background: #041c4c;
}

::v-deep .is-plain {
  background: #176ad2;
  border: 0;
  color: #fff;
}

::v-deep .el-time-spinner__item:hover:not(.disabled):not(.active) {
  background: #041c4c;
}

::v-deep .is-plain:hover {
  background: #176ad2;
  border: 0;
  color: #fff;
}

.details-title {
  font-weight: 700;
  color: #0096ff;
  font-size: 14px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}

.square {
  width: 5px;
  height: 5px;
  background-color: #0096ff;
  margin-right: 4px;
}

.interval {
  width: 100%;
  height: 2px;
  background-color: #082657;
  margin-bottom: 16px;
}

/* 执行结果 */
.status-dialog {
  margin: 16px 48px 16px 40px;
}

.status-form :deep().el-form-item__label {
  width: 67px;
  padding-right: 0;
  color: #e8f4ff;
  font-size: 12px;
  text-align: right;
  margin-right: 10px;
}

.status-form :deep().el-form-item__label::after {
  content: ":";
  margin: 0 3px;
}

.status-form :deep() .el-form-item__content {
  width: 374px;
}

.status-form :deep() .el-form-item {
  margin: 0 0 16px 0;
}

/* .status-form :deep() .approve-result {
  margin: 0 0 6px 0 !important;
} */
.status-form :deep() .el-textarea textarea {
  height: 35px;
  width: 100%;
  background-color: #061b45;
  border: 1px solid #09295b;
  font-size: 12px;
  color: #aed6ff;
}

.status-form :deep() .el-input input {
  height: 35px;
  width: 100%;
  background-color: #061b45;
  border: 1px solid #09295b;
  font-size: 12px;
  color: #aed6ff;
}

.status-form :deep() .el-rate,
.dialog-form :deep() .el-rate {
  margin-top: 10px;
}

.status-form :deep() .el-radio {
  margin-right: 16px;
  color: #aed6ff;
}

::v-deep .el-rate__text {
  display: none;
}
.dialog-text{
  text-align: center;
  margin: 50px 0;
}
.noImage{
  font-size: 14px;
  color: #e8f4ff;
}
::v-deep .el-input__inner{
    height: 40px !important;
  }
</style>
