import { getRequest, postRequest, gateway, urlTitle } from "@/utils/request";
import qs from "qs";
window.qs = qs;
// 抢修任务
export const rushRepairApi = {
  // 抢修任务列表
  queryRushRepair: (params) => {
    return getRequest(
      `${gateway}/operation/grab/maintain/task/page/list`,
      params
    );
  },
  // 查询任务项信息
  queryTaskItem: (params) => {
    return getRequest(`${gateway}/operation/grab/maintain/task/item`, params);
  },
  // 抢修任务详情
  queryTaskDetails: (id) => {
    return getRequest(`${gateway}/operation/grab/maintain/task/page/${id}`);
  },
  // 删除图片
  deletePicture: (id) => {
    return postRequest(`${gateway}/operation/grab/maintain/task/delete/${id}`);
  },
  // 修改图片
  updatePicture: (params) => {
    return postRequest(
      `${gateway}/operation/grab/maintain/task/upload`,
      params
    );
  },
  // 创建抢修任务
  createRushRepair: (params) => {
    return postRequest(`${gateway}/operation/grab/maintain/task/add`, params);
  },
  // 修改抢修任务
  updateRushRepair: (params) => {
    return postRequest(
      `${gateway}/operation/grab/maintain/task/update`,
      params
    );
  },
  // 修改抢修任务
  taskScoring: (params) => {
    return postRequest(`${gateway}/userScore/score`, params);
  },
  // 修改状态
  updateTaskStatus: (id, state, approverId, approverOpinion, params) => {
    return postRequest(
      `${gateway}/operation/grab/maintain/task/update/${id}/${state}?approverId=${approverId}&approverOpinion=${approverOpinion}`,
      params
    );
  },
  // 删除抢修任务
  deleteRushRepair: (id) => {
    return postRequest(`${gateway}/operation/grab/maintain/task/${id}`);
  },
};
