import { getRequest, postRequest, gateway, urlTitle } from "@/utils/request";
import { get, post } from "jquery";
import qs from "qs";
window.qs = qs;
// 试验任务
export const testTaskApi = {
  // 试验任务列表
  queryTestTask: (params) => {
    return getRequest(`${gateway}/operation/experiment/task/get`, params);
  },
  // 试验项列表
  queryTestItem: () => {
    return getRequest(`${gateway}/operation/experiment/task/all`);
  },
  // 试验任务列表
  queryTaskDetails: (id) => {
    return getRequest(`${gateway}/operation/experiment/task/get/${id}`);
  },
  // 修改图片
  updatePicture: (params) => {
    return postRequest(`${gateway}/operation/experiment/task/upload`, params);
  },
  // 用户列表
  queryUser: () => {
    return getRequest(`${urlTitle}/user/getUserList`);
  },
  // 创建试验任务
  createTestTask: (params) => {
    return postRequest(`${gateway}/operation/experiment/task/add`, params);
  },
  // 修改试验任务
  updateTestTask: (params) => {
    return postRequest(`${gateway}/operation/experiment/task/update`, params);
  },
  // 修改状态
  updateTaskStatus: (id, state, result, params,type) => {
    return postRequest(
      `${gateway}/operation/experiment/task/update/${id}/${state}?resultDesc=${result}&type=${type}`,
      params
    );
  },
  // 删除试验任务
  deleteTestTask: (id) => {
    return postRequest(`${gateway}/operation/experiment/task/delete/${id}`);
  },
};

/*
 **** 维修任务
 */

//查询
export const taskListSearch = (params) => {
  return getRequest(`${gateway}/operation/maintain/task/list`, params);
};
//维修任务新增
export const taskListAdd = (params) => {
  return postRequest(`${gateway}/operation/maintain/task/add`, params);
};
//维修任务项新增
export const taskListAddItem = (params) => {
  return postRequest(`${gateway}/operation/maintain/task/add/item`, params);
};
//修改维修任务状态
export const taskUpdateType = (params) => {
  return postRequest(
    `${gateway}operation/maintain/task/update/${params.id}/${params.state}`
  );
};
//维修任务详情查询
export const taskQueryId = (params) => {
  return getRequest(`${gateway}/operation/maintain/task/get/${params}`);
};
// 维修任务修改
export const taskListUpdate = (params) => {
  return postRequest(`${gateway}/operation/maintain/task/update`, params);
};
//维修任务项修改
export const taskListUpdateItem = (params) => {
  return postRequest(`${gateway}/operation/maintain/task/update/item`, params);
};
//维修任务详情更改
export const taskListUpdateItemDetails = (params) => {
  return `${gateway}/operation/maintain/task/update/item/details`, params;
};
//维修任务状态修改
export const taskListState = (params) => {
  return postRequest(`${gateway}/operation/maintain/task/update/state`, params);
};
//维修任务项保存
export const taskListItemUpdate = (params) => {
  return postRequest(
    `${gateway}/operation/maintain/task/add/item/details`,
    params
  );
};
//维修任务项修改
export const taskListItemAddUpdate = (params) => {
  return postRequest(
    `${gateway}/operation/maintain/task/update/item/details`,
    params
  );
};

//维修项删除
export const deleteTAskList = (params) => {
  return postRequest(`${gateway}/operation/maintain/task/${params}`);
};
//删除文件
export const deleteSysFile = (params) => {
  return postRequest(`${gateway}/sysfile/delete/${params}`);
};
//修改时上传文件
export const uploadSysFile = (params) => {
  return postRequest(`${gateway}/operation/maintain/task/upload`, params);
};
//评分
export const userScore = (params) => {
  return postRequest(`${gateway}/userScore/score`, params);
};
//维修项删除
export const taskItemDelete = (params) => {
  return postRequest(`${gateway}/operation/maintain/task/item/${params}`);
};
//根据场站id获取运维单位
export const getStaComList = (params) => {
  return getRequest(
    `${urlTitle}/station/info/getStaComList?stationId=${params}`
  );
};
// 获取所有运维单位列表
export const getOpeComList = () => {
  return getRequest(`${urlTitle}/companyInfo/getOpeComList`);
};
// 获取所有场站
export const getStationList = () => {
  return getRequest(`${urlTitle}/station/info/list`);
};
/**
 *
 * 考核管理
 */
//获取任务完成率-单位
export const operationInspectionTask = (params) => {
  return getRequest(
    `${gateway}/operationInspectionTask/task?company=${params}`
  );
};
//获取服务质量综合评分-单位
export const operationInspectionTaskEntirety = (params) => {
  return getRequest(
    `${gateway}/operationInspectionTask/entirety/task`,params);
};
//获取计划完成率-单位
export const operationInspectionTaskPlan = (params) => {
  return getRequest(
    `${gateway}/operationInspectionTask/plan/task`,params);
};
//获取任务完成率-场站
export const operationInspectionTaskYear = (params) => {
  return getRequest(
    `${gateway}/operationInspectionTask/task/year?station=${params}`
  );
};
//获取服务质量综合评分-场站
export const operationInspectionTaskYearEntirety = (params) => {
  return getRequest(
    `${gateway}/operationInspectionTask/entirety/task/year`,params);
};
//获取服务质量综合评分-场站
export const operationInspectionTaskStationPlan = (params) => {
  return getRequest(
    `${gateway}/operationInspectionTask/plan/task/year`,params);
};
